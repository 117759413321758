import type { Theme } from 'react-select';
import { parsePixelOrRem } from './BorderRadiusHelper';
import { useContext } from 'react';
import ThemeContext from '../../components/context/ThemeContext/ThemeContext';

function reactSelectTheme(theme: Theme, borderRadius) {
  return {
    ...theme,
    borderRadius: parsePixelOrRem(borderRadius),
    colors: {
      ...theme.colors,
      primary: 'var(--lia-bs-primary)',
      primary75: 'var(--lia-primary-light)',
      primary50: 'var(--lia-primary-lighter)',
      primary25: 'var(--lia-primary-lightest)',
      danger: 'var(--lia-bs-danger)',
      dangerLight: 'var(--lia-danger-lighter)',
      neutral0: 'var(--lia-bs-white)',
      neutral5: 'var(--lia-bs-gray-200)',
      neutral10: 'var(--lia-bs-gray-300)',
      neutral20: 'var(--lia-bs-gray-400)',
      neutral30: 'var(--lia-bs-gray-500)',
      neutral40: 'var(--lia-bs-gray-600)',
      neutral50: 'var(--lia-bs-gray-700)',
      neutral60: 'var(--lia-bs-gray-800)',
      neutral70: 'var(--lia-bs-gray-900)',
      neutral80: 'var(--lia-bs-gray-900)',
      neutral90: 'var(--lia-bs-black)'
    }
  };
}

function useThemeBorderRadius() {
  const { theme } = useContext(ThemeContext);
  return theme?.border?.radius ?? '5px';
}

export function useReactSelectTheme() {
  const borderRadius = useThemeBorderRadius();
  return theme => reactSelectTheme(theme, borderRadius);
}
