import React from 'react';
import { OverlayTrigger } from 'react-bootstrap';
import type { OverlayTriggerProps } from 'react-bootstrap/lib/OverlayTrigger';

type Props = Pick<OverlayTriggerProps, 'overlay' | 'show' | 'onToggle' | 'container' | 'children'>;

/**
 * Tag Editor Overlay Trigger.
 *
 * @author Willi Hyde
 */
const TagEditorOverlayTrigger: React.FC<React.PropsWithChildren<Props>> = ({
  overlay,
  show,
  onToggle,
  container,
  children
}) => {
  return (
    <OverlayTrigger
      trigger={['click']}
      overlay={overlay}
      placement="top-start"
      rootClose
      show={show}
      onToggle={onToggle}
      container={container}
    >
      {children}
    </OverlayTrigger>
  );
};

export default TagEditorOverlayTrigger;
