import type { ApolloQueryResult } from '@apollo/client';
import type {
  PresetTagsQuery,
  PresetTagsQueryVariables
} from '@aurora/shared-generated/types/graphql-types';
import { getLog } from '@aurora/shared-utils/log';
import dynamic from 'next/dynamic';
import React, { useContext, useEffect, useRef, useState } from 'react';
import type { OverlayInjectedProps } from 'react-bootstrap/lib/esm/Overlay';
import { useClassNameMapper } from 'react-bootstrap';
import type { OnChangeValue } from 'react-select';
import { SharedComponent } from '../../../enums';
import type { ElementFocusHandler } from '../../../helpers/ui/ComponentHelper/ComponentHelper';
import ComponentHelper from '../../../helpers/ui/ComponentHelper/ComponentHelper';
import Button from '../../common/Button/Button';
import { ButtonVariant } from '../../common/Button/enums';
import type { AppContextInterface } from '../../context/AppContext/AppContext';
import AppContext from '../../context/AppContext/AppContext';
import useQueryWithTracing from '../../useQueryWithTracing';
import useRegistrationStatus from '../../users/useRegistrationStatus';
import useTranslation from '../../useTranslation';
import getPresetTagsQuery from '../PresetTags/PresetTags.query.graphql';
import localStyle from './TagEditor.module.pcss';
import TagList from './TagList';
import type TagOption from './TagOption';
import { type CreatableTagOption, TagsQueryFetchType } from './TagSelector';
import { IconSize } from '../../common/Icon/enums';
import Icon from '../../common/Icon/Icon';
import Icons from '../../../icons';
import TagEditorOverlayTrigger from './TagEditorOverlayTrigger';

const TagSelector = dynamic(() => import('./TagSelector'));

interface Props {
  /**
   * The current tags, if any.
   */
  tags: TagOption[];

  /**
   * @callback
   * A callback function called when a tag is saved.
   */
  onTagAdd: (
    tag: OnChangeValue<CreatableTagOption, boolean>,
    actionMeta: { action: string }
  ) => Promise<unknown>;

  /**
   * @callback
   * A callback function called when a tag is removed.
   */
  onTagRemove: (tag: TagOption) => Promise<unknown>;

  /**
   * Is there another page of tags?
   */
  hasNextPage?: boolean;

  /**
   * @callback
   * A callback to load a page of tags.
   */
  onPageLoad?: () => Promise<ApolloQueryResult<unknown> | void>;

  /**
   * @callback
   * A callback function when the focus blurs from the selector.
   */
  onBlur?: () => void;
  /**
   * Whether the field should be focused. If set 'true' button element of this component will
   * be focused.
   */
  useFocus?: boolean;
  /**
   * Allows for a custom focus handler called when the field wants to be
   * focused on initial render of the form.
   */
  focusHandler?: ElementFocusHandler;
  /**
   * Click handler when a tag is clicked.
   */
  onTagClick?: (tag: TagOption) => void;
  /**
   * Whether the tags are clickable.
   */
  isPreview?: boolean;
  /**
   * Wheather to show the add tag button inline. Default false.
   */
  isInlineAddTag?: boolean;
  /**
   *  Whether to show remove tag button.
   */
  canRemoveTag?: boolean;
  /**
   * Whether the user can tag or not.
   */
  canTag?: boolean;
  /**
   * whether field is present in admin app or enduser app
   */
  isAdminSetting?: boolean;
  /**
   * Whether to show remove tag dialog
   */
  showRemoveTagDialog?: boolean;
  /**
   * Which query to use to fetch the tags
   */
  tagsQueryFetchType: TagsQueryFetchType;
  /**
   * The node scope to select tags
   */
  nodeId?: string;
}

const log = getLog(module);

/**
 * Display message tags.
 *
 * Enables adding, searching, and removing of tags to a message.
 *
 * @author Dolan Halbrook, Willi Hyde
 */

const TagEditor: React.FC<React.PropsWithChildren<Props>> = ({
  onTagAdd,
  onTagRemove,
  tags,
  hasNextPage = false,
  onBlur = (): void => null,
  onPageLoad = (): Promise<ApolloQueryResult<unknown> | void> => Promise.resolve(),
  useFocus,
  focusHandler,
  onTagClick,
  isPreview = true,
  isInlineAddTag = false,
  canRemoveTag = true,
  canTag = true,
  isAdminSetting,
  showRemoveTagDialog = false,
  tagsQueryFetchType,
  nodeId
}) => {
  const cx = useClassNameMapper(localStyle);
  const { isRegistered } = useRegistrationStatus();
  const { formatMessage, loading: textLoading } = useTranslation(SharedComponent.TAG_EDITOR);
  const targetReference = useRef<HTMLButtonElement>(null);
  const hasBeenFocused = useRef(false);
  const [showTagSelector, setShowTagSelector] = useState(false);
  const containerRef = useRef();
  const { contextNode } = useContext<AppContextInterface>(AppContext);
  const contextNodeId = nodeId === undefined ? contextNode.id : nodeId;

  useEffect(() => {
    if (useFocus) {
      ComponentHelper.focusHandler(hasBeenFocused, targetReference.current, focusHandler);
    }
  });

  const {
    data: presetTagsData,
    loading: presetTagsLoading,
    error: presetTagsError
  } = useQueryWithTracing<PresetTagsQuery, PresetTagsQueryVariables>(module, getPresetTagsQuery, {
    variables: {
      id: contextNodeId
    },
    skip: !isRegistered
  });

  if (textLoading || presetTagsLoading) {
    return null;
  } else if (presetTagsError) {
    log.error(presetTagsError, 'Error fetching preset tags');
  }

  const presetTags =
    presetTagsData?.predefinedTags
      ?.map(text => ({
        label: text,
        value: text
      }))
      .filter(item => !tags?.some(tag => tag.label.toLowerCase() === item.label.toLowerCase())) ??
    [];
  //checks if preset tags list is empty for post of type "PRESET_ONLY"
  const isPresetOnlyTagsListEmpty =
    tagsQueryFetchType === TagsQueryFetchType.FETCH_PRESET_TAGS ? presetTags.length === 0 : false;

  const showAddTagButton = canTag && isPreview && !isPresetOnlyTagsListEmpty;

  const renderOverlay: React.FC<React.PropsWithChildren<OverlayInjectedProps>> = ({ ref }) => {
    return (
      <div className={cx('lia-tag-box')} ref={ref}>
        <TagSelector
          tagsQueryFetchType={tagsQueryFetchType}
          tagsToExclude={tags}
          onAdd={onTagAdd}
          presetTags={presetTags}
          onBlur={() => {
            setShowTagSelector(false);
            onBlur();
          }}
          nodeId={contextNodeId}
        />
      </div>
    );
  };

  return tags?.length > 0 || canTag ? (
    <>
      {showAddTagButton && !isInlineAddTag && (
        <TagEditorOverlayTrigger
          overlay={renderOverlay}
          show={showTagSelector}
          onToggle={setShowTagSelector}
          container={containerRef.current}
        >
          <Button
            variant={ButtonVariant.UNSTYLED}
            ref={targetReference}
            className={cx('lia-add-tag-button lia-g-link-btn')}
          >
            {formatMessage('tagAddTitle')}
          </Button>
        </TagEditorOverlayTrigger>
      )}
      <div className={cx('lia-tag-wrap')} ref={containerRef}>
        <TagList
          canRemoveTag={canRemoveTag}
          canTag={canTag}
          tags={tags}
          showRemoveTagDialog={showRemoveTagDialog}
          onTagRemove={onTagRemove}
          hasNextPage={hasNextPage}
          onPageLoad={onPageLoad}
          onTagClick={onTagClick}
          isPreview={isPreview}
          isAdminSetting={isAdminSetting}
        />
        {isInlineAddTag && showAddTagButton && (
          <TagEditorOverlayTrigger
            overlay={renderOverlay}
            show={showTagSelector}
            onToggle={setShowTagSelector}
            container={containerRef.current}
          >
            <Button
              variant={ButtonVariant.NO_VARIANT}
              className={cx('lia-tag lia-tag-add badge badge-light')}
              ref={targetReference}
              aria-label={formatMessage('addTag.ariaLabel')}
            >
              <Icon
                icon={Icons.AddIcon}
                size={IconSize.PX_12}
                testId="TagEditor.AddIcon"
                className={cx('lia-tag-icon')}
              />
              <span className={cx('lia-tag-text lia-tag-add-text')}>
                {formatMessage('tagAddInlineTitle')}
              </span>
            </Button>
          </TagEditorOverlayTrigger>
        )}
      </div>
    </>
  ) : null;
};
export default TagEditor;
