/**
 * Returns the border radius as a numeric value representing pixel units.
 *
 * @param input
 * @returns Number value representing pixel units
 *
 * @author Willi Hyde
 */
export function parsePixelOrRem(input: string): number {
  // Use regular expressions to match and extract the numeric part and unit (px or rem)
  const match = input.match(/^([\d.]+)(px|rem)$/);

  if (match) {
    const value = Number.parseFloat(match[1]);
    // eslint-disable-next-line prefer-destructuring
    const unit = match[2];

    if (unit === 'px') {
      return value;
    } else if (unit === 'rem') {
      return value * 16;
    }
  }

  // If the input doesn't match the expected format, return 5
  return 5;
}
